import './App.css';
import Main from './Pages/Main';

function App() {
  
  return (
    <div className="App overflow-hidden bg-[#fcfcff]">
      <Main />
    </div>
  );
}

export default App;
